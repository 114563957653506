import React, { useEffect, useState } from 'react'
import { DndProvider, TouchTransition, MouseTransition } from 'react-dnd-multi-backend'
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { CartesBas } from './CartesBas';
import { CartesHaut } from './CartesHaut';
import Logique from './Logique';
import { Button, message } from 'antd';
import { Helmet } from 'react-helmet';
import { dataOrdre } from './data';
import fleche from '../../images/fleche.png';
import { Link } from 'react-router-dom';
import Ad from '../../components/commun/AdSense';
import Resultat from './Resultat';
import { creerRaccouciTitre } from './utilitaire';
const HTML5toTouch = {
  backends: [
    {
      id: 'html5',
      backend: HTML5Backend,
      transition: MouseTransition,
    },
    {
      id: 'touch',
      backend: TouchBackend,
      options: { enableMouseEvents: true },
      preview: true,
      transition: TouchTransition,
    },
  ],
}
let game = new Logique();
let no = 0;


export default function JeuOrdre(props) {

  const [afficheCommentaire, setAfficheCommentaire] = useState(false);
  const [afficheResultat, setAfficheResultat] = useState(false);
  const [commentaire, setCommentaire] = useState('');
  const [titre, setTitre] = useState('');
  const [explication, setExplication] = useState('');
  const [afficheAide, setAfficheAide] = useState(true);

  const [afficheFin, setAfficheFin] = useState(false);
  const [nbAide, setNbAide] = useState(2);

  useEffect(() => () => game.reset(), []);
  function fin(resultat) {
    setAfficheResultat(true);
    if (game.nbErreurs === 0) {
      message.success("Bravo ! Tu es un champion 🍾");
    }
    else {
      message.error("Mauvais ordre");
    }
  }

  const [afficheJeu, setAfficheJeu] = useState(false);

  useEffect(() => {

    no = props.match.params.no == null ? 1 : props.match.params.no;
    game.no = no;
    game.setCategorie()
    game.setType();
    game.construireDonnees();
    game.ajouteObserverAfficheCommentaire(setAfficheCommentaire);
    game.ajouteObserverCommentaire(setCommentaire);
    game.ajouteObserverAfficheFin(setAfficheFin);
    game.ajouteObserverFin(fin);
    setAfficheJeu(true);
    let info = game.obtenirTitre();
    setTitre(info.titre);
    let msg = game.obtenirInfoTitre();
    if (info.commentaire) {
      message.success("Tu peux cliquer sur les cartes pour plus d'information.");
      msg += " Clique sur les cartes pour obtenir de l'aide."
    }
    setExplication(msg);
  }, []);


  function nouveauJeu() {

    game.resetData();
    game.construireDonnees();
    resetJeu();
  }

  function resetJeu() {
    setAfficheJeu(false);
    setAfficheResultat(false);
    setAfficheCommentaire(false);
    setAfficheFin(false);
    setAfficheAide(true);
    setNbAide(2);
    window.setTimeout(() => {
      setAfficheJeu(true);
    }, 150)
    let info = game.obtenirTitre();
    setTitre(info.titre);
    let msg = game.obtenirInfoTitre();
    if (info.commentaire) {
      msg += " Clique sur les cartes pour obtenir de l'aide."
    }
    setExplication(msg);
  }

  function recommencer() {

    console.log()
    nouveauJeu();
  }

  function clicLien(event) {
    const id = parseInt(event.currentTarget.id);
    console.log(id);
    game.no = id;
    game.setCategorie()
    game.setType();
    resetJeu();
    nouveauJeu(id);

  }

  useEffect(() => {
    if (nbAide === 0) setAfficheAide(false);
  }, [nbAide]);


  function aide() {
    setNbAide(prevState => prevState - 1);
    let tabTotal = game.verifierVictoire();
    let tabErreurs = tabTotal.filter(x => x === true);
    message.success(`Il y a ${tabErreurs.length} carte(s) bien placée(s).`,3)
  }

  function finDuJeu() {
    game.finDuJeu();
  }

  return (<React.Fragment>

    <Helmet>
      <body style="background-repeat: no-repeat;background-attachment: fixed; background-image: radial-gradient(circle farthest-corner at 92.4% 11.7%, rgb(164 0 0) 0%, rgba(2, 83, 185, 1) 100.2%);" />
      <title>Trier les cartes</title>
      <meta name="description" content="Dans ce jeu passionnant de culture générale vous devez classer des cartes. Testez vos connaissances en histoire, en géographie et en divertissement et devenez un maître du classement !">
      </meta></Helmet>

    {afficheJeu && <div className='plateauJeuOrdre'>
      <div className={afficheResultat ? 'noDrag' : ''}>
        <DndProvider options={HTML5toTouch}>
          <CartesHaut game={game}></CartesHaut>
          <div className="centre margeHautBas noDrag"><img src={fleche} alt="fleche"></img></div>
          <CartesBas game={game}></CartesBas>
        </DndProvider>
      </div>  {afficheCommentaire && <div className='commentaireOrdre'>
        <div className='boxCommentaireOrdre' dangerouslySetInnerHTML={{ __html: commentaire }}>
        </div>
      </div>}
      {afficheResultat && <div className='msgErreurOrdre'>{game.nbErreurs === 0 ? <React.Fragment><Resultat typeExo={'chm' + creerRaccouciTitre(titre)}></Resultat> <div className='centre moyenneFont' > Super, tu as gagné !</div></React.Fragment> : <div className='centre'>Tu as fait  {game.nbErreurs} {game.nbErreurs === 1 ? 'faute' : 'fautes'}</div>}</div>}

      <div className='titreOrdre centre'>Remettre dans l'ordre</div>
      {!afficheResultat &&
        <React.Fragment>
           {afficheFin && <Button type="primary" size="large" onClick={finDuJeu}>Résultats</Button>}
          {afficheAide && <Button className="margeHaut10" onClick={aide}>Aide ({nbAide})</Button>}
         
          <div className='infoOrdre centre'>
            <div className='explication moyenneFont'>{titre}</div>

            <div>{explication}</div>
          </div></React.Fragment>}
      {afficheResultat && <div className='msgErreurOrdre'>
        <div className='centre margeHaut10'><Button onClick={recommencer}>Recommencer</Button>   <div><Link to={'/'}>Accueil</Link></div> </div>
        <Ad></Ad> <div>Autres jeux du même type</div>
        <div>{dataOrdre.map((jeu, i) => <div className='lienOrdre' id={i} key={500 + i} onClick={clicLien}>{jeu.titre}</div>)}
        </div></div>
      }

    </div>}
  </React.Fragment>)
}