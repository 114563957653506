import React, { Component } from 'react';
import { Input, Button} from 'antd';
import { Link} from 'react-router-dom';
import Ad from '../../components/commun/AdSense';
import Confetti from 'react-confetti';
import { readFirstName, addFirstName } from '../../components/commun/localStorage';

export default class ResultatGagne extends Component {


    constructor(props) {
        super(props);
        let prenom = readFirstName() || '';
        this.state = {
            prenom,
            afficherPrenom : prenom=== '' ? true : false
        }
    }
    componentDidMount() {
        console.log(this.state.prenom);
        if (this.state.prenom !== '')
        {
            this.envoyerMessage(this.state.prenom);
        }

    }
   

    ok = () => {
        if (this.state.prenom !== '' ) {
            addFirstName(this.state.prenom);
            this.envoyerMessage(this.state.prenom);
        }        
        this.setState({ afficherPrenom: false });
    }
    ajouterPrenom = () => {
        if (this.state.afficherPrenom) {
            return  <div className="centre"><p>Entrez votre prénom pour être inscrit au tableau d'honneur.</p>
                <Input maxLength={18} style={{ width: '200px' }}  placeholder="Votre prénom" onChange={(event) => this.setState({ prenom: event.target.value })} value={this.state.prenom}></Input>
                <Button  type='primary' onClick={this.ok}>OK</Button></div>

        }
    }

    envoyerMessage = (prenom) =>
    {
        let url = new URL(process.env.REACT_APP_URL_QUIZAJOUTERTABLEAU);
        var data = new FormData();
        data.append('prenom', prenom);
        data.append('type', 'suite')
        fetch(url, {
            method: "POST",
            body: data
        })
    }

    ajouterPrenom = () => {
        if (this.state.afficherPrenom) {
            return  <div className="centre"><p>Entrez votre prénom pour être inscrit au tableau d'honneur.</p>
                <Input maxLength={18} style={{ width: '200px' }}  placeholder="Votre prénom" onChange={(event) => this.setState({ prenom: event.target.value })} value={this.state.prenom}></Input>
                <Button  type='primary' onClick={this.ok}>OK</Button></div>

        }
    }

    render()
    {
        return <div>
           <Confetti />
           {this.ajouterPrenom()}
            <div className='finSuite'>Bravo ! tu as réussi le 10 à la suite</div>
            <p className='centre moyenneFont'> 🏆Tu es un champion 👏</p>
            <p>🏅{this.state.prenom}, ton nom sera gravé sur notre tableau d'honneur.</p>
          
            <div className='margeHaut10'>Nous proposons d'autres formes de quiz avec des questions différentes.</div>
            <div> <a href="https://concours.evalquiz.com/presculture">Jeu de plateau de la culture générale</a></div>
            <div>Pour les amateurs d'histoire</div>
            <div><Link to={'/juste-nombre/histoire'}>Trouver la bonne année historique</Link></div> 
            <div className='centre margeHaut10'><Link to={'/'}>Voir <b>le tableau d'honneur</b></Link></div> 
             <div className='centre margeHaut10'><Button type="primary" onClick={this.props.recommencer}>Recommencer</Button></div>
        <Ad></Ad></div>
    }
}