import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { Card, Row, Col, Avatar } from 'antd';
import border from '../../images/border.png';
import { Helmet } from 'react-helmet';
import Ad from '../commun/AdSense';
import { creerTitreHonneur, creerGroupeCategorie } from '../../jeux/ordre/utilitaire';
import fleur from '../../images/fleur.png';


import borderHonneur from '../../images/borderHonneur.png';
import { dataOrdre } from '../../jeux/ordre/data';

const { Meta } = Card;

export default class Presentation extends Component {

  constructor() {
    super();
    this.hubConnection = null;
    this.state =
    {
      resultats: []
    }
  }

  creerCategorie()
  {
    let tabCategorie = [];
    let lesCategories = creerGroupeCategorie();
    for (let index = 0; index < lesCategories.length; index++) {
      tabCategorie.push({categorie : lesCategories[index].nom,tabJeux : dataOrdre.filter(x => x.categorie === lesCategories[index].no) })  
    }
    console.log(tabCategorie);
    return tabCategorie;
  }

  async componentDidMount() {

    let url = new URL(process.env.REACT_APP_URL_QUIZLIRETABLEAU);
    var data = new FormData();
    fetch(url, {
      method: "POST",
      body: data
    }).then(res => res.json())
      .then(res => {
        this.setState({ resultats: res });
      }
      );


  }

  legendeTableauHonneur(type) {
    if (type.includes('ns')) {
      return type.includes('histoire') ? 'Date mystère ' + type.replace('ns', '') : 'Nombre mystère ' + type.replace('ns', '')
    }
    else if (type.includes('chm')) {

      return creerTitreHonneur(type.replace('chm', ''))
    }
    else {
      return "10 à la suite"
    }
  }


  render() {

    return <div className="margeHaut">

      <Helmet>
        <title>Participez à un concours de culture générale en ligne</title>
        <meta name="description" content="Venez vous amuser avec vos amis sur des questions de culture générale dans le style du jeu Qui veut gagner des millions" />
      </Helmet>

      <h1 className="titre centre">Quiz Royal</h1>
      <div className='centre moyenneFont'>3 quiz pour les champions, les 10 à la suite, trier les cartes et les dates de l'histoire</div>
<p>Bienvenue dans notre série de quiz et de jeux destinés aux esprits curieux et passionnés par la culture générale et l'histoire ! Si vous aimez relever des défis intellectuels et tester vos connaissances, vous êtes au bon endroit.</p>

<div className="centre margeHaut20"><img src={fleur} alt="présentation"></img></div>


<div className="centre margeHaut20"><img src={border} alt="bordure"></img></div>

      <h2 className="titre centre">Les 10 à la suite</h2>
      <div className="centre"><img src={border} alt="bordure"></img></div>
      <p>Découvrez <b>Les 10 à la suite</b>, le jeu de culture générale qui vous met au défi de répondre correctement à 10 questions à la suite, inspiré du célèbre jeu "Qui veut gagner des millions" ou des "4 à la suite" du jeu "Question pour un champion". Testez vos connaissances, montez les échelons et devenez le champion de la culture générale ! Êtes-vous prêt pour le défi ?</p>

      <div><Link to={'/suite'}><div className='quizHisto quizSuite'><div>Les 10 à la suite</div></div></Link></div>
      <div className="centre margeHaut20"><img src={border} alt="bordure"></img></div>
      <h2 className="titre centre ">Trier les cartes</h2>
      <div className="centre"><img src={border} alt="bordure"></img></div>
      <p>Bienvenue dans ce passionnant jeu de culture générale. Choisis un thème parmi l'histoire, la géographie ou le divertissement. Ensuite, classe une série de cartes selon la date d'un événement, l'altitude, la superficie ou toute autre caractéristique définie.</p>
      <div className="margeHaut20">
        {this.creerCategorie().map((info, i) =>
        <div key={i}><h3>{info.categorie}</h3>
        <div className='alignement'>{info.tabJeux.map((jeu, j) =>  <Link key={((i+1) * 100) + j} to={'/ordre/' + jeu.id}><div className={"carreLien ombre " +  (jeu.categorie ===1 ? "titreCarreOrdre" : jeu.categorie ===2 ? "titreCarreOrdreGeo" : "titreCarreOrdreDivers") }>{jeu.titre}</div></Link>
        )}</div></div>)}
      </div>
      <p>Le PIB (Produit Intérieur Brut) par pays (ou PIB total) représente la valeur totale de tous les biens et services produits dans un pays au cours d'une année. Le PIB par habitant est obtenu en divisant le PIB total par le nombre d'habitants du pays. Le Luxembourg a un PIB par habitant élevé mais un PIB total faible car sa population est petite. Les nombres sont en dollars.</p>
      <div className="centre margeHaut20"><img src={border} alt="bordure"></img></div>
      <h2 className="titre centre ">Les grandes dates de l'histoire</h2>

      <div className="centre"><img src={border} alt="bordure"></img></div>
      <p className="moyenneFont centre">Le grand quiz de l'histoire pour les amateurs de quiz très difficiles. Saurez-vous retrouver la bonne date d'un évènement à partir d'un indice ou d'une photo ?</p>

      <p>Un jeu simple et divertissant, on vous présente une question ou une photo et vous devez deviner l'année correspondant à l'événement de la photo. Si vous trouvez la bonne réponse, vous gagnez 100 points. Chaque partie comporte 5 questions, permettant d'atteindre un score maximum de 500 points. Attention, plus l'événement est récent, plus vous devez être précis pour ne pas perdre de points. Ce jeu est idéal pour revisiter les grandes dates de l'Histoire tout en s'amusant.
        Il y a 5 questions par partie, le score maximum est donc de 500 points. Plus la date est récente plus vous devez être précis sous peine de perdre de nombreux points. Un jeu idéal pour réviser les grandes dates de l'Histoire tout en s'amusant. </p>

      <div><Link to={'/juste-nombre/histoire'}><div className='quizHisto'><div className='centre'>Quiz </div><div>Dates historiques</div></div></Link></div>
      <p><b>Attention</b> certaines images historiques peuvent troubler les personnes sensibles.</p>
      <div>Pour vous aider et vous répérer dans le temps vous pouvez consulter nos frises chronologiques :</div>
      <ul><li><a href="/blog/frisefrance" >Frise chronologique</a> de l'histoire de France</li>
        <li><a href="/blog/frisemonde">Frise chronologique</a> des grands événements de l'histoire mondiale</li>
        <li><a href="/blog/friseprehistoire">Frise chronologique</a> de la préhistoire</li>
        <li><a href="/blog/friseperiode">Frise chronologique</a> des différentes périodes de l'humanité</li>
      </ul>
      <h2>Les autres quiz basés sur les nombres</h2>
      <p>Divers thèmes vous sont proposés</p>
      <ul>
        <li className="fontMoyenne"><b>Géographie</b></li>
        <div>Des questions axées sur la géographie : distance entre deux villes, population, point culminant... </div>
        <li className="fontMoyenne"><b>Divertissement</b></li>
        <div>Un petit jeu amusant et assez difficile, connaissez-vous les prix des boissons, des repas, des objets... </div>
        <li className="fontMoyenne"><b>Divertissement : La mode féminine</b></li>
        <div>Des questions sur les prix des vêtements féminins, sur les articles de luxe et ceux qui sont bon marché. Peut-on déterminer le prix d'un vêtement à partir d'une simple photo ?  </div>
      </ul>
      <div className="margeHaut20 alignement">


        <Link to={'/juste-nombre/geographie'}><div  className="carreLien carrePourcentage ombre"><div className="titrePourcentage">Géographie</div></div></Link>
        <Link to={'/juste-nombre/divers'}><div  className="carreLien carrePourcentage ombre"><div className="titrePourcentage">Divertissement</div></div></Link>
        <Link to={'/juste-nombre/mode'}><div  className="carreLien carrePourcentage ombre"><div className="titrePourcentage">La mode féminine</div></div></Link>

      </div>
      <Ad></Ad>
      <div className="centre margeHautBas"><img src={borderHonneur} alt="bordure"></img></div>
      <h1 className="centre">Le tableau d'honneur</h1>
      <p>Ils sont les champions, ils ont réussi à être numéro 1.</p>
      <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        {this.state.resultats.map((info, i) => <Col key={i} xs={{ span: 12 }} sm={{ span: 6 }} md={{ span: 4 }}>
      <div className="cardHonneur"><div className='contentHonneur'>
        <div>🏆 Champion 🏆</div>
        <div>{this.legendeTableauHonneur(info.type)}</div>
        <div className="couleurHonneur moyenneFont nomHonneur">{info.prenom.length > 16 ? info.prenom.slice(0, 16) : info.prenom}</div>
        <div>le {info.date}</div>
        </div></div>
      </Col>)}
      </Row>

      <p>Vous pouvez nous contacter si vous avez des remarques, des propositions ou des problèmes de droit à l'image en consultant la page des <a href="https://evalquiz.com/home/faq">mentions légales.</a>. Vous pouvez aussi nous contacter sur <a href="https://twitter.com/evalquiz">Twitter</a></p>


      <p>Le respect de votre vie privée est notre priorité.</p>
      <div className="centre"><img src={border} alt="bordure"></img></div>

    </div>
  }
}

{/* <Card type="flex" bodyStyle={info.type.includes('ns') ? { backgroundColor: '#a9bbff' } : { backgroundColor: '#a9ffff' }} >

<Meta
  avatar={<Avatar src={laurier} />}
  title={<span className="couleurHonneur">{info.prenom.length > 18 ? info.prenom.slice(0, 18) : info.prenom}</span>}
/>
{this.legendeTableauHonneur(info.type)}
<p>le {info.date}</p>
</Card> */}