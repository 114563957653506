
import { dataOrdre } from "./data";
import { creerRaccouciTitre, obtenirType } from './utilitaire';
const moisFrancais = [
    'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
    'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
];
const NOMBRECARTES = 6;
export default class Logique {
    constructor() {

        this.reset();
        this.no = 0;
        this.categorie = 0;
        this.type = 0;
    }





    setCategorie() {
        this.categorie = dataOrdre[this.no].categorie;
    }



    setType() {
        this.type = dataOrdre[this.no].type;
        console.log(this.type);
    }



    static melangerTableau(tableau) {
        for (let i = tableau.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [tableau[i], tableau[j]] = [tableau[j], tableau[i]]; // Échange des éléments
        }
        return tableau;
    }
    obtenirInfoTitre() {
        console.log(this.type);
        return obtenirType(this.type);

    }
    construireOrdre() {
        if (this.categorie === 1) {
            return this.construireOrdreHistoire();
        }
        if (this.categorie === 2 || this.categorie === 3 || this.categorie === 4) {
            return this.construireOrdreGeo();
        }
    }

    construireOrdreHistoire() {
        let date;
        let dataOrdreCopie = JSON.parse(JSON.stringify(dataOrdre[this.no].jeu));
        let dataOrdreMelange = Logique.melangerTableau(dataOrdreCopie).slice(0, NOMBRECARTES);


        for (let index = 0; index < NOMBRECARTES; index++) {

            let data = dataOrdreMelange[index];

            // if (data.date.toString().includes('-')) {
            //     data.dateUtilisateur = data.date;

            //     data.date = new Date(parseInt(data.date.toString().substring(0, 4)), 1, 1).getTime();
            //     console.log(data.dateUtilisateur);
            //     continue;
            // }

            data.dateUtilisateur = data.info1 + (data.info2 !== '' ? " - " + data.info2 : '');
            if (data.info1.length <= 4) {
                data.date = new Date(data.info1, 1, 1).getTime();
                continue;

            }
            if (data.info1.length > 7) {
                let decoupe = data.info1.split('/');
                data.date = new Date(decoupe[2], decoupe[1], decoupe[0]).getTime();
                continue;

            } else {
                let decoupe = data.info1.split('/');
                data.date = new Date(decoupe[1], decoupe[0], 1).getTime();
                continue;
            }
        }
        dataOrdreMelange.sort((a, b) => a.date - b.date);

        for (let index = 0; index < dataOrdreMelange.length; index++) {
            let data = dataOrdreMelange[index];
            data.ordre = index;
        }

        let tableauMelange = Logique.melangerTableau(dataOrdreMelange);
        return tableauMelange;

    }



    construireOrdreGeo() {
        let date;
        let dataOrdreCopie = JSON.parse(JSON.stringify(dataOrdre[this.no].jeu));
        let dataOrdreMelange = Logique.melangerTableau(dataOrdreCopie).slice(0, NOMBRECARTES);


        for (let index = 0; index < NOMBRECARTES; index++) {

            let data = dataOrdreMelange[index];

            // if (data.date.toString().includes('-')) {
            //     data.dateUtilisateur = data.date;

            //     data.date = new Date(parseInt(data.date.toString().substring(0, 4)), 1, 1).getTime();
            //     console.log(data.dateUtilisateur);
            //     continue;
            // }

            data.dateUtilisateur = data.info1;
            let decoupe = data.info1.split(' ');

            data.date = decoupe[0];
        }
        dataOrdreMelange.sort((a, b) => a.date - b.date);

        for (let index = 0; index < dataOrdreMelange.length; index++) {
            let data = dataOrdreMelange[index];
            data.ordre = index;
        }

        let tableauMelange = Logique.melangerTableau(dataOrdreMelange);
        return tableauMelange;

    }

    reset() {
        this.tabCartesHaut = [];
        this.tabCartesBas = [];
        this.observerCartesBas = null;
        this.observerCartesHaut = null;
        this.observerCommentaire = null;
        this.observerAfficheCommentaire = null;
        this.observerAfficheFin = null;
        this.observerFin = null;
        this.commentaire = '';
        this.nbErreurs = 0;
    }

    resetData() {
        this.tabCartesHaut = [];
        this.tabCartesBas = [];
        this.commentaire = '';
        this.nbErreurs = 0;
    }
    construireDonnees() {

        let donneesJeu = this.construireOrdre();
        console.log(donneesJeu);
        for (let index = 0; index < NOMBRECARTES; index++) {
            this.tabCartesHaut.push({ pos: index, titre: '', place: 'haut', etat: 'vide', ordre: -1, dateUtilisateur: '', commentaire: '' });
            this.tabCartesBas.push({ pos: index, titre: donneesJeu[index].titre, place: 'bas', etat: 'rempli', ordre: donneesJeu[index].ordre, dateUtilisateur: donneesJeu[index].dateUtilisateur, commentaire: donneesJeu[index].commentaire });
        }


    }


    verifierEtatHaut(pos) {
        return this.tabCartesHaut[pos].etat === 'vide';
    }

    verifierEtatBas(pos) {
        return this.tabCartesBas[pos].etat === 'vide';
    }
    ajouteObserverAfficheCommentaire(setAfficheCommentaire) {
        this.observerAfficheCommentaire = setAfficheCommentaire;
    }

    ajouteObserverAfficheFin(setAfficheFin) {
        this.observerAfficheFin = setAfficheFin;
    }

    ajouteObserverCommentaire(setCommentaire) {
        this.observerCommentaire = setCommentaire;
    }

    ajouteObserverCartesHaut(setCartesHaut) {
        this.observerCartesHaut = setCartesHaut;
    }

    ajouteObserverCartesBas(setCartesBas) {
        this.observerCartesBas = setCartesBas;
    }
    ajouteObserverFin(fin) {
        this.observerFin = fin;
    }

    modifierCartesHaut() {
        this.observerCartesHaut([...this.tabCartesHaut]);
    }

    modifierCartesBas() {
        this.observerCartesBas([...this.tabCartesBas]);
    }

    modifierAfficheCommentaire(etat) {

        this.observerAfficheCommentaire(etat);
    }

    modifierAfficheFin(etat) {

        this.observerAfficheFin(etat);
    }

    modifierCommentaire(texte, titre) {
        this.observerCommentaire("<div class='titreCommentaireOrdre'>" + titre + "</div>" + "<div class='texteCommentaireOrdre'>" + texte + "</div>");
    }

    modifierFin(resultat, nbErreurs) {

        this.observerFin(resultat, nbErreurs);
    }
    obtenirTitre() {
        console.log(this.no)
        let titre = dataOrdre[this.no].titre;
        let commentaire = dataOrdre[this.no].commentaire;
        return { titre, commentaire };
    }

    modifierCartes(element, pos, hauteur) {


        if (hauteur === "bas" && element.place === "bas") {
            this.modifBasVersBas(element, pos);
        }
        if (hauteur === "bas" && element.place === "haut") {
            this.modifHautVersBas(element, pos);
        }
        if (hauteur === "haut" && element.place === "haut") {
            this.modifHautVersHaut(element, pos);
        }
        if (hauteur === "haut" && element.place === "bas") {
            this.modifBasVersHaut(element, pos);
        }
    }

    modifBasVersBas(element, pos) {
        this.tabCartesBas[element.pos].etat = 'vide';
        this.tabCartesBas[pos].etat = 'rempli';
        this.tabCartesBas[pos].titre = this.tabCartesBas[element.pos].titre;
        this.tabCartesBas[pos].commentaire = this.tabCartesBas[element.pos].commentaire;
        this.tabCartesBas[pos].ordre = this.tabCartesBas[element.pos].ordre;
        this.tabCartesBas[pos].dateUtilisateur = this.tabCartesBas[element.pos].dateUtilisateur;
        this.tabCartesBas[element.pos].titre = '';
        this.modifierCartesBas();
    }

    modifBasVersHaut(element, pos) {
        this.tabCartesBas[element.pos].etat = 'vide';
        this.tabCartesHaut[pos].etat = 'rempli';
        element.place = 'haut';
        this.tabCartesHaut[pos].titre = this.tabCartesBas[element.pos].titre;
        this.tabCartesHaut[pos].commentaire = this.tabCartesBas[element.pos].commentaire;
        this.tabCartesHaut[pos].ordre = this.tabCartesBas[element.pos].ordre;
        this.tabCartesHaut[pos].dateUtilisateur = this.tabCartesBas[element.pos].dateUtilisateur;
        this.tabCartesBas[element.pos].titre = '';

        this.modifierCartesBas();
        this.modifierCartesHaut();
        this.boutonFinJeu();
    }

    boutonFinJeu() {
        if (this.tabCartesHaut.findIndex(x => x.etat === 'vide') === -1) {
            this.modifierAfficheFin(true);

        }
        else {
            this.modifierAfficheFin(false);
        }
    }

    finDuJeu() {
        if (this.tabCartesHaut.findIndex(x => x.etat === 'vide') === -1) {
            let resultat = this.verifierVictoire();
            if (this.nbErreurs === 0) {
                for (let index = 0; index < this.tabCartesBas.length; index++) {
                    this.tabCartesHaut[index].titre = `<div class="${!resultat[index] ? 'erreurOrdre' : ''}"><div>${this.tabCartesHaut[index].titre}</div><div class="dateOrdre">${this.tabCartesHaut[index].dateUtilisateur}<div></div>`
                }
            }
            else {
                console.log(this.tabCartesHaut);
                let bonnesReponses = JSON.parse(JSON.stringify(this.tabCartesHaut));
                bonnesReponses.sort((a, b) => a.ordre - b.ordre);
                for (let index = 0; index < this.tabCartesBas.length; index++) {
                    for (let index = 0; index < this.tabCartesHaut.length; index++) {
                        this.tabCartesBas[index].titre = `<div><div>${bonnesReponses[index].titre}</div><div class="dateOrdre">${bonnesReponses[index].dateUtilisateur}<div></div>`

                    }
                    this.tabCartesHaut[index].titre = `<div class="${!resultat[index] ? 'erreurOrdre' : ''}"><div>${this.tabCartesHaut[index].titre}</div></div>`
                }
            }
            this.modifierFin();
            this.modifierCartesHaut();
            this.modifierCartesBas();

        }
    }

    modifHautVersHaut(element, pos) {
        this.tabCartesHaut[element.pos].etat = 'vide';
        this.tabCartesHaut[pos].etat = 'rempli';
        this.tabCartesHaut[pos].titre = this.tabCartesHaut[element.pos].titre;
        this.tabCartesHaut[pos].commentaire = this.tabCartesHaut[element.pos].commentaire;
        this.tabCartesHaut[pos].ordre = this.tabCartesHaut[element.pos].ordre;
        this.tabCartesHaut[pos].dateUtilisateur = this.tabCartesHaut[element.pos].dateUtilisateur;
        this.tabCartesHaut[element.pos].titre = '';

        this.modifierCartesHaut();
        this.boutonFinJeu();
    }

    modifHautVersBas(element, pos) {
        this.tabCartesHaut[element.pos].etat = 'vide';
        this.tabCartesBas[pos].etat = 'rempli';
        element.place = 'bas';
        this.tabCartesBas[pos].titre = this.tabCartesHaut[element.pos].titre;
        this.tabCartesBas[pos].commentaire = this.tabCartesHaut[element.pos].commentaire;
        this.tabCartesBas[pos].ordre = this.tabCartesHaut[element.pos].ordre;
        this.tabCartesBas[pos].dateUtilisateur = this.tabCartesHaut[element.pos].dateUtilisateur;
        this.tabCartesHaut[element.pos].titre = '';
        this.modifierCartesBas();
        this.modifierCartesHaut();
        this.boutonFinJeu();
    }

    verifierVictoire() {
        let tabErreur = [];
        this.nbErreurs = 0;
        for (let index = 0; index < this.tabCartesHaut.length; index++) {
            if (this.tabCartesHaut[index].ordre !== index) {
                tabErreur.push(false);
                this.nbErreurs++;
            }
            else {
                if (this.tabCartesHaut[index].etat !== 'vide') {
                    tabErreur.push(true);
                }
            }

        }
        return tabErreur;
    }
    canDropTabHaut(pos) {
        console.log(pos);
        return this.tabCartesHaut[pos].etat === 'vide';
    }

    canDropTabBas(pos) {
        return this.tabCartesBas[pos].etat === 'vide';
    }

}