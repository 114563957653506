function creerRaccouciTitre(titre) {
    switch (titre) {
        case "L'histoire de France":
            return "histoirefrance";
        case "Les événements de la Première Guerre mondiale":
            return "premièreguerremondiale";
        case "Les Rois de France (très difficile)":
            return "roisfrance";
        case "Les événements de la Seconde Guerre mondiale":
            return "secondeguerremondiale";
        case "La période napoléonienne (difficile)":
            return "napoléon";
        case "Les présidents de la 5e République":
            return "5republique";
        case "La science et les grandes découvertes":
            return "science";
        case "L'histoire des États-Unis":
            return "etatsunis";
        case "Les droits des femmes en France":
            return "droitsfemme";
        case "Les grandes batailles":
            return "batailles";
        case "Les grands événements du 21e siècle":
            return "21siecle";
        case "Les grands événements du 20e siècle":
            return "20siecle";
        case "Grandes découvertes et exploits sportifs":
            return "decouvertes";
        case "Les tableaux célèbres":
            return "grandstableaux";
        case "Les pays et leurs populations":
            return "payspop";
        case "Les villes du monde et leurs populations":
            return "villespop";
        case "Les villes françaises et leurs populations":
            return "villesfrapop";
        case "Les montagnes les plus hautes de chaque continent":
            return "montagneshaut";
        case "Les plus grands lacs du monde":
            return "lacmonde";
        case "Les prénoms masculins les plus donnés en 2023":
            return "prenommasc";
        case "Les prénoms féminins les plus donnés en 2023":
            return "prenomfem";
        case "Les grands films par année depuis 2010":
            return "films2010";
        case "Les grands sites internet et applications":
            return "internet";
        case "Grands jeux vidéo : des débuts à aujourd'hui":
            return "jeuxvideo";
        case "Les 6 titres les plus écoutés sur Spotify":
            return "titresspotify";
        case "Le PIB par habitant":
            return "PIBhabitant"
        case "Le PIB par pays":
            return "PIBpays"
        case "Les mouvements artistiques":
            return "mouvart"
             case "Grands hommes français à partir du XIXe siècle":
            return "grandshommes19"
    }
}

function creerTitreHonneur(titre) {
    switch (titre) {
        case "histoirefrance":
            return "Histoire France";
        case "premièreguerremondiale":
            return "Première Guerre mondiale"
        case "roisfrance":
            return "Rois de France ";
        case "secondeguerremondiale":
            return "Seconde Guerre mondiale";
        case "napoléon":
            return "Napoléon";
        case "5republique":
            return "5e République";
        case "science":
            return "La science";
        case "etatsunis":
            return "Les États-Unis";
        case "droitsfemme":
            return "Droits des femmes";
        case "batailles":
            return "Les batailles";
        case "21siecle":
            return "Le 21e siècle"
        case "20siecle":
            return "Le 20e siècle"
        case "decouvertes":
            return "Grandes découvertes";
        case "grandstableaux":
            return "Les tableaux";
        case "payspop":
            return "Populations pays";
        case "villespop":
            return "Populations villes monde";
        case "villesfrapop":
            return "Populations villes France";
        case "montagneshaut":
            return "Les montagnes";
        case "lacmonde":
            return "Les plus grands lacs du monde";
        case "prenommasc":
            return "Prénoms masculins";
        case "prenomfem":
            return "Prénoms féminins";
        case "films2010":
            return "Films 2010"
        case "internet":
            return "Internet et applications";
        case "jeuxvideo":
            return "Jeux vidéo";
        case "titresspotify":
            return "Titres Spotify"
        case "PIBhabitant":
            return "PIB/habitant"
        case "PIBpays":
            return "PIB Pays"
        case "mouvart":
            return "Mouvements art"
             case "grandshommes19":
            return "Grands hommes XIXe "

    }
}

function creerGroupeCategorie() {
    let categorie = [{ no: 1, nom: 'Histoire' }, { no: 2, nom: 'Géographie' }, { no: 3, nom: 'Divertissement' }, { no: 4, nom: 'Économie' }];
    return categorie;
}

function obtenirType(type) {
    switch (type) {
        case 1: return "Glisse les cartes pour les remettre dans l'ordre chronologique. On prend en compte la date du début de l'événement."
        case 2: return "Glisse les cartes en fonction de leur population de la plus petite à gauche à la plus grande à droite."
        case 3: return "Glisse les cartes en fonction de leur hauteur de la plus petite à la plus grande."
        case 4: return "Glisse les cartes en fonction de leur superficie de la plus petite à la plus grande."
        case 5: return "Glisse les cartes en fonction du nombre du plus petit au plus grand."
        case 6: return "Glisse les cartes en fonction de l'année de sortie de la plus ancienne à la plus récente."
        case 7: return "Glisse les cartes en fonction du nombre d'écoutes (le plus petit à gauche)."
        case 8: return "Glisse les cartes en fonction de la richesse (le plus riche à droite, le plus pauvre à gauche)."
          case 9: return "Glisse les cartes en fonction de l'année de naissance du personnage. Le plus ancien à gauche."


    }
}

export { creerRaccouciTitre, creerTitreHonneur, creerGroupeCategorie, obtenirType };